/* styles.css */
.soft-shadow {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.legend {
    list-style: none;
}

.legend li {
    float: left;
    margin-right: 10px;
}

.legend span {
    border: thin solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
}

.scrollable-cell {
  max-height: 355px;  /* Maximum cell height */
  overflow-y: auto;   /* Enables vertical scrolling */
  display: block;     /* Changes the cell to block layout which respects the max-height */
}

/* Optional: Style the scrollbar for better aesthetics */
.scrollable-cell::-webkit-scrollbar {
  width: 6px;         /* Scrollbar width */
}

.scrollable-cell::-webkit-scrollbar-thumb {
  background-color: darkgrey;  /* Scrollbar color */
  border-radius: 10px;         /* Scrollbar corner rounding */
}


/* src/index.css */
.bg-independence-day {
  background-image: url('assets/images/independence_day.gif');
  background-size: cover;
}
