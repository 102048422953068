/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #AACF0D;
    color: #fff;
}

#logo {
    flex-grow: 1;
    background:url('./assets/images/menu_logo.png')left top no-repeat;
    height:30px;
    text-indent: -9000px;
    /* Ensures the logo can grow if needed, but also helps push the submenu to the right */
}

#submenu {
    display: flex;
    justify-content: space-around; /* Adjust this as needed to control spacing between links */
    align-items: center;
}

#submenu a {
    text-decoration: none;
    color: #fff; /* Adjust the color as needed */
    margin: 0 10px; /* Gives some space between the links */
    /* Additional styling for the links as needed */
}
#submenu li{
  list-style: none;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.sidebar {
    flex: 1;
    background-color: #f4f4f4;
    padding: 1rem;
    min-width: 150px;
    max-width: 150px;
    height: calc(100vh - 2rem); /* Adjust the height as per header and footer */
    position: sticky;
    top: 60px; /* Height of the header */
}

.content {
    flex: 3;
    padding: 1rem;
}

/* .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    position: relative;
    width: 100%;
} */
/* In your CSS file */

