/* src/pages/PayrollPage.css */

@media print {
  /* Hide all buttons to prevent them from appearing in the printout */
  button {
    display: none;
  }

  /* Ensure table headers repeat on each printed page */
  thead {
    display: table-header-group;
  }

  /* Optimize table layout for printing */
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 12px; /* Adjust as needed */
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  /* Prevent page breaks within table rows */
  tr {
    page-break-inside: avoid;
  }

  /* Set page size and margins */
  @page { 
    size: auto; 
    margin: 14mm; /* Adjusted margin for 30% table reduction */
  }
  table { 
    page-break-inside: auto; 
    transform: scale(0.96); /* Reduce size by 30% */
    transform-origin: top left; /* Ensure scaling starts from the top-left corner */
    margin: 0 auto; /* Center the table */
  }
  tr { 
    page-break-inside: avoid; 
    page-break-after: auto; 
  }
  thead { 
    display: table-header-group; 
  }
  tbody { 
    display: table-row-group; 
  }
  tfoot { 
    display: table-footer-group; 
  }
}